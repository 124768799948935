import { useLoadScript } from "~/lib/utils/load-script";
import { useRequestInfo } from "~/lib/utils/request-info";

declare global {
  interface Window {
    grecaptcha: any;
    onRecaptchaLoad: any;
  }
}

export const useReCaptcha = () => {
  const { reCaptchaSiteKey } = useRequestInfo();

  return useLoadScript(
    `https://www.recaptcha.net/recaptcha/api.js?render=${reCaptchaSiteKey}`,
  );
};
